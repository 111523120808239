<template>
  <PageDeveloperLayout>

    <template v-slot:layoutBreadcrumb>
      <ABreadcrumb :items="breadcrumb.items" />
    </template>

    <template v-slot:loyoutBody>

      <v-card-title>Component</v-card-title>
            <v-card-text>
              <p>To create a tbulator table, we are going to use a following component. this component has,
                <ul>
                  <li>Template Section</li>
                  <li>Script Section</li>
                </ul>
              </p>
              <h6>Template Section </h6>
              <CopyToClipBoard :text-to-copy=templateSection></CopyToClipBoard>
              <VueCodeHighlight language="javascript">
                <pre>
                  
                 {{ templateSection }}
              </pre>
              </VueCodeHighlight>

              <h6>Script Section</h6>
              <p>we have to include all code in beteen the "script" tags</p>
              <p>First import following items</p>

              <CopyToClipBoard :text-to-copy=scriptSectionImport></CopyToClipBoard>
              <VueCodeHighlight>
                <pre>
                  
                  {{ scriptSectionImport }}
                </pre>
              </VueCodeHighlight>

              <p>followng codes shoud be include inside the `export default{}` function</p>
              <p>1. Props :</p>

              <CopyToClipBoard :text-to-copy=scriptSectionProps></CopyToClipBoard>
              <VueCodeHighlight>
                <pre>
                 
                  {{ scriptSectionProps }}
                </pre>
              </VueCodeHighlight>

              <p>2. Data :</p>

              <CopyToClipBoard :text-to-copy=scriptSectionData></CopyToClipBoard>
              <VueCodeHighlight>
                <pre>
                  
                  {{ scriptSectionData }}
                </pre>
              </VueCodeHighlight>

              <p>3. Created :</p>

              <CopyToClipBoard :text-to-copy=scriptSectionCreated></CopyToClipBoard>
              <VueCodeHighlight>
                <pre>
                  
                  {{ scriptSectionCreated }}
                </pre>
              </VueCodeHighlight>

              <p>4. Mounted :</p>

              <CopyToClipBoard :text-to-copy=scriptSectionMounted></CopyToClipBoard>
              <VueCodeHighlight>
                <pre>
                  
                  {{ scriptSectionMounted }}
                </pre>

                <p>Note: after coppy the mounted section, replace the following codes.
                  <ul>
                    <li>REPLACE CODE ONE : `&sortBy=${field}&sortDesc=${dir}`</li>
                    <li>REPLACE CODE TWO : `?page=${page}&itemsPerPage=${size}${sortQuery}`</li>
                    <li>REPLACE CODE THREE : `Showing ${currentPage} page of ${totalPages} total`;</li>
                  </ul>
                </p>

              </VueCodeHighlight>
              
            </v-card-text>
    </template>

  </PageDeveloperLayout>
</template>
  
  <script>
//BOC:[breadcrumb]
import PageDeveloperLayout from "./layouts/PageDeveloperLayout.vue";
import Breadcrumb from "@/objects/breadcrumb";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-okaidia.css";
/* import "vue-code-highlight/themes/window.css"; */
//EOC
import { mapState } from "vuex";
export default {
  components: {
    CopyToClipBoard,
    VueCodeHighlight,
    PageDeveloperLayout,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    templateSection: `<template><div
                      ref="table"
                      :layout="layout"
                      :apiUrl="apiUrl"
                      :paginationSize="paginationSize"
                      :autoColumns="autoColumns"
                      :columns="columns"
                      :paginationSizeSelector="paginationSizeSelector"
                      :fiterCondition="fiterCondition"
                    >
                  </div>
                </template>`,

    scriptSectionImport: `
    import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
    import Api from "@/objects/api"
    import Model from "@/objects/model"
    import Service from "@/objects/service"`,

    scriptSectionProps: `
    props: {
    apiUrl: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "",
    },

    paginationSize: {
      type: Number,
      default: 10,
    },
    autoColumns: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    paginationSizeSelector: {
      type: Array,
      default: () => [10, 20, 30, 40, 50],
    },
  },`,

    scriptSectionData: `
    data: () => ({
    tabulator: null, //variable to hold your table
    tableData: [], //data for table to display
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    fiterCondition: [],
  }),`,

    scriptSectionCreated: `
    created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },`,

    scriptSectionMounted: `
  mounted() {
    this.tabulator = new Tabulator(this.$refs.table, {
      pagination: true,
      layout: this.layout,
      paginationMode: "remote", //enable remote pagination
      ajaxURL: this.apiUrl, //set url for ajax request
      sortMode: "remote", //enable remote sort
      filterMode: "remote", //enable remote filter
      paginationSize: this.paginationSize, //optional parameter to request a certain number of rows per page
      paginationInitialPage: 1, //optional parameter to set the initial page to load
      paginationButtonCount: 3,
      autoColumns: this.autoColumns,
      columns: this.columns,
      paginationSizeSelector: this.paginationSizeSelector,
      movableColumns: true,
      columnHeaderSortMulti: false,
      ajaxURLGenerator: function (url, config, params) {
        const { page, size, sort } = params; // filter

        const sortQuery = sort
          .map(({ field, dir }) => {
            if (dir == "desc") dir = true;
            else dir = false;
            return  REPLACE CODE ONE;
          })
          .join("&");
		return url + REPLACE CODE TWO;
      },
      ajaxResponse: function (url, params, response) {
        // Must configure with server side
        let last_page = response.info.totalPages;
        //console.log(response.info.totalPages);
        return {
          data: response.data,
          last_page,
        };
      },
      paginationCounter: function (
        pageSize,
        currentRow,
        currentPage,
        totalRows,
        totalPages
      ) {
        return REPLACE CODE THREE;
      },
    });
  },`,
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Component",
      to: { name: "" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped>
/* @import url('../../../../public/css/services/developer/style.css'); */
</style>